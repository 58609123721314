<template>
  <v-container>
    <v-card>
      <v-toolbar class="secondary" dark flat>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>{{title_page()}}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-form v-model="valid" @submit.prevent="submit">
          <v-row>
            <v-col cols="12" class="d-flex justify-center">
              <video ref="videoSource"  :poster="ossURL +'/'+ editItemData.profile_image" controls width="80%" v-if="editItemData.video_key">
                <source :src="ossURL + '/' + editItemData.video_key">
              </video>
            </v-col>
            <v-progress-linear color="secondary" v-if="UploadVideo.uploading" :value="UploadVideo.progressUpload"></v-progress-linear>
            <v-col cols="12" class="d-flex justify-center">
              <input type="file" ref="inputVideo" accept="video/mp4" :multiple="false" @change="detectVideo($event)" />
              <v-btn large depressed color="secondary" @click="uploadVideo()"><v-icon left>upload</v-icon>上传视频</v-btn>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="editItemData.title"
                :rules="[rules.required]"
                type="text"
                label="视频标题"
                hint="请输入视频的标题"
                outlined
                dense
              ></v-text-field>
              <v-menu
                v-model="dataTimeMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="editItemData.publish_date"
                    label="发布日期"
                    hint="请选择知识的发布日期"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    clearable
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="editItemData.publish_date" @input="dataTimeMenu = false" no-title scrollable></v-date-picker>
              </v-menu>
              <v-text-field
                v-model="editItemData.author"
                type="text"
                label="作者"
                hint="请输入知识作者或发布方"
                outlined
                clearable
                dense
              ></v-text-field>
              <v-autocomplete
                v-model="editItemData.catalog"
                :items="KBCatalog"
                :rules="[rules.selected]"
                autocomplete="off"
                item-text="name"
                item-value="_id"
                outlined
                dense
                label="请选择知识所属目录"
              ></v-autocomplete>
              <v-combobox
                v-model="editItemData.label"
                label="知识标签"
                hint="使用回车键可以输入多个标签。标签将作为用户检索内容之一。"
                deletable-chips
                multiple
                small-chips
                outlined
                dense
              >
              </v-combobox>
              <v-autocomplete
                v-if="catalogList && catalogList.length"
                v-model="editItemData.service"
                :items="flatGroupCatalog(catalogList)"
                item-text="name"
                item-value="_id"
                autocomplete="off"
                label="请选择支持服务内容"
                hide-details
                outlined
                dense
                chips
                small-chips
                deletable-chips
                multiple
                clearable
              >
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-action>
                      <v-checkbox :input-value="data.attrs.inputValue"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="6" class="d-flex justify-center align-center">
              <input type="file" ref="inputCover" accept="image/*" :multiple="false" @change="detectCover($event)" />
              <v-img
                :src="editItemData.profile_image ? ossURL +'/'+ editItemData.profile_image : '/static/error/empty_state.svg'"
                dark
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="375px"
                :aspect-ratio="32/9">
                <v-row align="end" class="lightbox white--text pa-2 fill-height">
                  <v-col>
                    <div class="subheading">{{ editItemData.publish_date }} {{ editItemData.author }}</div>
                    <div class="text-h3">{{ editItemData.title }} <v-btn text @click="uploadCover()" class="float-right"><v-icon left>add_photo_alternate</v-icon>上传知识封面</v-btn></div>
                  </v-col>
                </v-row>
              </v-img>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" depressed @click="submit" :disabled="!valid">保存</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import store from "@/store";
import {
  EDIT_KNOWLEDGE,
  FETCH_CATALOG_LIST, FETCH_KNOWLEDGE,
  FETCH_KNOWLEDGE_CATALOG_LIST,
  FETCH_UPLOAD,
  PUBLISH_KNOWLEDGE
} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";
import {mapGetters} from "vuex";
import axios from "axios";
import moment from "moment/moment";

export default {
  data() {
    return {
      rules: {
        required: (value) => value === 0 || !!value || "请输入内容.",
        selected: (value) => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        },
        negative: (val) => val > 0 || "不能小于0",
        counter: value => value.length <= 80 || '最多输入80个字。',
      },
      title_page: function () {
        return (this.$route.params.id !== '1' && this.$route.params.id !== '0') ? '编辑知识' : '新建知识';
      },
      valid: true,
      UploadVideo: {
        uploading: false,
        progressUpload: 0,
      },
      UploadCover: {
        uploading: false,
        progressUpload: 0,
      },
      KBCatalog: [],
      editItemData: {},
      dataTimeMenu: false,
    }
  },
  mounted() {},
  computed: {
    ...mapGetters([
      "uploadSign",
      "KBCatalogList",
      "catalogList"
    ]),
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_KNOWLEDGE_CATALOG_LIST),
      store.dispatch(FETCH_CATALOG_LIST),
    ]).then(() => {
      next();
    });
  },
  created() {
    if (this.$route.params.id !== '0') this.getKnowledge(this.$route.params.id)
    if (this.$route.query.group) {
      this.editItemData.group = this.$route.query.group
      let groupInfo = this.KBCatalogList.find(item => item._id === this.$route.query.group)
      if (groupInfo) {
        this.KBCatalog = groupInfo.children
        if (this.$route.query.catalog) {
          this.editItemData.catalog = this.$route.query.catalog
        }
      }
    }
  },
  watch: {},
  methods: {
    goBack() {
      let pId = ''
      let open = ''
      if (this.$route.query.pId) pId = this.$route.query.pId
      if (this.$route.query.open) open = this.$route.query.open


      this.editItemData = Object.assign({}, {});
      this.$router.push({path: '/dealer/knowledge?pId=' + pId + '&open=' + open });

    },
    getKnowledge(id) {
      store.dispatch(FETCH_KNOWLEDGE, id)
        .then(data => {
          this.editItemData = Object.assign({}, data)
          if (data.publish_date) this.editItemData.publish_date = moment(data.publish_date).format('YYYY-MM-DD');
          this.editItemData.group = data.catalog.parentId._id
          let groupInfo = this.KBCatalogList.find(item => item._id === data.catalog.parentId._id)
          if (groupInfo) {
            this.KBCatalog = groupInfo.children
            this.editItemData.catalog = data.catalog._id;
          }
        });
    },
    submit() {
      let requestData = this.editItemData;
      requestData.isVideo = true;
      if (!requestData.video_key) {
        store.commit(SET_ERROR, {msg: '请上传视频后保存知识。'})
        return
      }
      if (!requestData.profile_image) {
        store.commit(SET_ERROR, {msg: '请上传封面后保存知识。'})
        return
      }

      if (this.editItemData._id) {
        if (typeof requestData.publisher === "object") {
          requestData.publisher = requestData.publisher._id
        }
        if (typeof requestData.catalog === "object") {
          requestData.catalog = requestData.catalog._id
        }
        if (typeof requestData.editor === "object") {
          requestData.editor = requestData.editor._id
        }

        store.dispatch(EDIT_KNOWLEDGE, this.editItemData)
          .then(({data}) => {
            store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
            this.getKnowledge(data._id);
          })
          .catch((err) => {
            store.commit(SET_ERROR, {msg: err.response.data.message});
          });
      } else {
        store.dispatch(PUBLISH_KNOWLEDGE, requestData)
          .then(({data}) => {
            store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
            this.getKnowledge(data._id);
            let pId = ''
            let open = ''
            if (this.$route.query.pId) pId = this.$route.query.pId
            if (this.$route.query.open) open = this.$route.query.open
            this.$router.push({path: '/dealer/knowledge/edit/video/' + data._id +'?pId=' + pId + '&open=' + open});
          })
          .catch((err) => {
            store.commit(SET_ERROR, {msg: err.response.data.message});
          });
      }

    },
    uploadCover() {
      store.dispatch(FETCH_UPLOAD)
        .then(() => {
          this.$refs.inputCover.click()
        })
        .catch(err => {
          store.commit(SET_ERROR, {msg: err});
        });
    },
    uploadVideo() {
      store.dispatch(FETCH_UPLOAD)
        .then(() => {
          this.$refs.inputVideo.click();
        })
        .catch(err => {
          store.commit(SET_ERROR, {msg: err});
        });
    },
    detectCover(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x], 'cover');
      })
    },
    detectVideo(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x], 'video');
      })
    },
    async upload(file, assigned) {
      let uploadPath = 'knowledge/image/' + this.uploadSign.key + file.name.substring(file.name.lastIndexOf(".")),
        uploadProgress = this.UploadCover,
        inputComponent = this.$refs.inputCover,
        editField = 'profile_image';

      if (assigned === 'video') {
        uploadProgress = this.UploadVideo;
        uploadPath = 'knowledge/video/'+ this.uploadSign.key + file.name.substring(file.name.lastIndexOf("."));
        inputComponent = this.$refs.inputVideo;
        editField = 'video_key'
      }

      uploadProgress.uploading = true;

      let param = new FormData();
      param.append('name', file.name);
      param.append('key', uploadPath);
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, file.name);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          uploadProgress.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };

      await axios.post(this.uploadSign.host, param, config)
        .then(() => {
          uploadProgress.uploading = false;
          uploadProgress.progressUpload= 0;
          inputComponent.value = '';
          this.editItemData[editField] = uploadPath;

          if (assigned === 'video' && this.$refs.videoSource) {
            this.$refs.videoSource.src = uploadPath;
            this.$refs.videoSource.load();
          }

          this.$forceUpdate();
        })
        .catch((error) => {
          uploadProgress.uploading = false;
          uploadProgress.progressUpload= 0;
          inputComponent.value = '';
          store.commit(SET_ERROR, {msg: error.message});
        });
    },
    flatGroupCatalog(arrGroup) {
      let arrRoles = []
      arrGroup.forEach((groupItem) => {
        arrRoles.push({ header: groupItem.name })
        groupItem.children.forEach((catalogItem) => {
          arrRoles.push({ _id: catalogItem._id, name: catalogItem.name, group: groupItem.name, process: catalogItem.process })
        })
        arrRoles.push({ divider: true })
      })
      return arrRoles
    },
  },
}
</script>
<style scoped>
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
