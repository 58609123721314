var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    staticClass: "secondary",
    attrs: {
      "dark": "",
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v(_vm._s(_vm.title_page()))]), _c('v-spacer')], 1), _c('v-card-text', [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "d-flex justify-center",
    attrs: {
      "cols": "12"
    }
  }, [_vm.editItemData.video_key ? _c('video', {
    ref: "videoSource",
    attrs: {
      "poster": _vm.ossURL + '/' + _vm.editItemData.profile_image,
      "controls": "",
      "width": "80%"
    }
  }, [_c('source', {
    attrs: {
      "src": _vm.ossURL + '/' + _vm.editItemData.video_key
    }
  })]) : _vm._e()]), _vm.UploadVideo.uploading ? _c('v-progress-linear', {
    attrs: {
      "color": "secondary",
      "value": _vm.UploadVideo.progressUpload
    }
  }) : _vm._e(), _c('v-col', {
    staticClass: "d-flex justify-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('input', {
    ref: "inputVideo",
    attrs: {
      "type": "file",
      "accept": "video/mp4",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectVideo($event);
      }
    }
  }), _c('v-btn', {
    attrs: {
      "large": "",
      "depressed": "",
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.uploadVideo();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("upload")]), _vm._v("上传视频")], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "视频标题",
      "hint": "请输入视频的标题",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editItemData.title,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "title", $$v);
      },
      expression: "editItemData.title"
    }
  }), _c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "发布日期",
            "hint": "请选择知识的发布日期",
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": ""
          },
          model: {
            value: _vm.editItemData.publish_date,
            callback: function callback($$v) {
              _vm.$set(_vm.editItemData, "publish_date", $$v);
            },
            expression: "editItemData.publish_date"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.dataTimeMenu,
      callback: function callback($$v) {
        _vm.dataTimeMenu = $$v;
      },
      expression: "dataTimeMenu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function input($event) {
        _vm.dataTimeMenu = false;
      }
    },
    model: {
      value: _vm.editItemData.publish_date,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "publish_date", $$v);
      },
      expression: "editItemData.publish_date"
    }
  })], 1), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "作者",
      "hint": "请输入知识作者或发布方",
      "outlined": "",
      "clearable": "",
      "dense": ""
    },
    model: {
      value: _vm.editItemData.author,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "author", $$v);
      },
      expression: "editItemData.author"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "items": _vm.KBCatalog,
      "rules": [_vm.rules.selected],
      "autocomplete": "off",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": "",
      "label": "请选择知识所属目录"
    },
    model: {
      value: _vm.editItemData.catalog,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "catalog", $$v);
      },
      expression: "editItemData.catalog"
    }
  }), _c('v-combobox', {
    attrs: {
      "label": "知识标签",
      "hint": "使用回车键可以输入多个标签。标签将作为用户检索内容之一。",
      "deletable-chips": "",
      "multiple": "",
      "small-chips": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editItemData.label,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "label", $$v);
      },
      expression: "editItemData.label"
    }
  }), _vm.catalogList && _vm.catalogList.length ? _c('v-autocomplete', {
    attrs: {
      "items": _vm.flatGroupCatalog(_vm.catalogList),
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "label": "请选择支持服务内容",
      "hide-details": "",
      "outlined": "",
      "dense": "",
      "chips": "",
      "small-chips": "",
      "deletable-chips": "",
      "multiple": "",
      "clearable": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_typeof(data.item) !== 'object' ? [_c('v-list-item-content', {
          domProps: {
            "textContent": _vm._s(data.item)
          }
        })] : [_c('v-list-item-action', [_c('v-checkbox', {
          attrs: {
            "input-value": data.attrs.inputValue
          }
        })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(data.item.name)
          }
        })], 1)]];
      }
    }], null, false, 3296285918),
    model: {
      value: _vm.editItemData.service,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "service", $$v);
      },
      expression: "editItemData.service"
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "d-flex justify-center align-center",
    attrs: {
      "cols": "6"
    }
  }, [_c('input', {
    ref: "inputCover",
    attrs: {
      "type": "file",
      "accept": "image/*",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectCover($event);
      }
    }
  }), _c('v-img', {
    attrs: {
      "src": _vm.editItemData.profile_image ? _vm.ossURL + '/' + _vm.editItemData.profile_image : '/static/error/empty_state.svg',
      "dark": "",
      "gradient": "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)",
      "height": "375px",
      "aspect-ratio": 32 / 9
    }
  }, [_c('v-row', {
    staticClass: "lightbox white--text pa-2 fill-height",
    attrs: {
      "align": "end"
    }
  }, [_c('v-col', [_c('div', {
    staticClass: "subheading"
  }, [_vm._v(_vm._s(_vm.editItemData.publish_date) + " " + _vm._s(_vm.editItemData.author))]), _c('div', {
    staticClass: "text-h3"
  }, [_vm._v(_vm._s(_vm.editItemData.title) + " "), _c('v-btn', {
    staticClass: "float-right",
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.uploadCover();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("add_photo_alternate")]), _vm._v("上传知识封面")], 1)], 1)])], 1)], 1)], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "depressed": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }